import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ArrowSmallLeftIcon } from "@heroicons/react/20/solid";

const DownloadPage = () => {
    return (
        <div className="flex sm:bg-light-primary dark:bg-dark-primary flex-col overflow-hidden justify-center pt-12 items-center">
            <div className="flex flex-col gap-5 min-h-[calc(100vh-168px)] dark:bg-dark-secondary bg-light-secondary pt-12 px-5 sm:px-16 w-full max-w-[1050px] lg:rounded-3xl relative ">
                <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, type: "tween" }}
                    viewport={{ once: true }}
                    className="flex justify-start flex-col gap-2 mb-12 mt-8"
                >
                    <h2 className="text-[34px] font-light text-neutral-600 dark:text-light-primary">Download Our Mobile App</h2>
                    <p className="text-neutral-600 dark:text-light-primary">
                        Access your parent portal on the go with our mobile applications.
                    </p>
                </motion.div>

                <div className="flex flex-col md:flex-row justify-center items-center gap-8 md:gap-16 py-8">
                    {/* iOS Download */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, type: "tween", delay: 0.2 }}
                        viewport={{ once: true }}
                        className="flex flex-col items-center gap-4"
                    >
                        <div className="relative">
                            <a
                                href="https://apps.apple.com/us/app/creovia-parent/id6738869692"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:opacity-90 transition-opacity"
                            >
                                <img
                                    src="/assets/Products/ios-download.png"
                                    alt="Download on App Store"
                                    className="rounded-lg shadow-lg max-w-[400px] md:max-w-[450px] w-full"
                                />
                            </a>
                            <div className="absolute bottom-4 right-4">
                                <a
                                    href="https://apps.apple.com/us/app/creovia-parent/id6738869692"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bg-[#3444D1] text-white px-4 py-2 rounded-lg font-medium hover:opacity-90 transition-opacity inline-block"
                                >
                                    Download
                                </a>
                            </div>
                        </div>
                        <a
                            href="https://apps.apple.com/us/app/creovia-parent/id6738869692"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:opacity-90 transition-opacity"
                        >
                            <img
                                src="/assets/images/en-us.svg"
                                alt="App Store"
                                className="h-12 w-auto"
                            />
                        </a>
                    </motion.div>

                    {/* Android Download */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, type: "tween", delay: 0.4 }}
                        viewport={{ once: true }}
                        className="flex flex-col items-center gap-4"
                    >
                        <div className="relative">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.creovia.parent_portal&hl=en_US"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:opacity-90 transition-opacity"
                            >
                                <img
                                    src="/assets/Products/android-download.png"
                                    alt="Get it on Google Play"
                                    className="rounded-lg shadow-lg max-w-[400px] md:max-w-[450px] w-full"
                                />
                            </a>
                            <div className="absolute bottom-4 right-4">
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.creovia.parent_portal&hl=en_US"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bg-[#3444D1] text-white px-4 py-2 rounded-lg font-medium hover:opacity-90 transition-opacity inline-block"
                                >
                                    Download
                                </a>
                            </div>
                        </div>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.creovia.parent_portal&hl=en_US"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:opacity-90 transition-opacity"
                        >
                            <img
                                src="/assets/images/GetItOnGooglePlay_Badge_Web_color_English.png"
                                alt="Google Play"
                                className="h-12 w-auto"
                            />
                        </a>
                    </motion.div>
                </div>

                <motion.div
                    initial={{opacity: 0}}
                    whileInView={{opacity: 1}}
                    transition={{duration: 0.5, type: "tween", delay: 0.6}}
                    viewport={{once: true}}
                    className="text-center text-neutral-600 dark:text-light-primary mt-8"
                >
                    <motion.div
                        initial={{opacity: 0}}
                        whileInView={{opacity: 1}}
                        transition={{duration: 0.5, type: "tween", delay: 0.6}}
                        viewport={{once: true}}
                        className="text-center text-neutral-600 dark:text-light-primary mb-12 hidden md:block"
                    >
                        <p>Scan the QR code or click to download</p>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
};

export default DownloadPage;