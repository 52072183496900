import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ArrowSmallLeftIcon } from "@heroicons/react/20/solid";

const PrivacySection = ({ title, children }) => (
    <motion.div
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, type: "tween" }}
        viewport={{ once: true }}
        className="flex justify-start flex-col gap-4 mb-12"
    >
        <h2 className="text-[34px] font-light text-neutral-600 dark:text-light-primary">{title}</h2>
        <div className="text-neutral-600 dark:text-light-primary">
            {children}
        </div>
    </motion.div>
);

const PrivacyPolicy = () => {
    return (
        <div className="flex sm:bg-light-primary dark:bg-dark-primary flex-col overflow-hidden justify-center items-center py-40">
            <div className="flex flex-col gap-5 dark:bg-dark-secondary bg-light-secondary pt-12 px-5 sm:px-16 w-full max-w-[1050px] lg:rounded-3xl relative">
                <Link
                    to="/"
                    className="absolute top-8 left-8 flex items-center gap-2 text-blue-600 dark:text-[#60A5FA] hover:opacity-80 transition-opacity"
                >
                    <ArrowSmallLeftIcon className="h-5 w-5" />
                    <span>Back to Home</span>
                </Link>

                <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, type: "tween" }}
                    viewport={{ once: true }}
                    className="flex justify-start flex-col gap-2 mb-12 mt-8"
                >
                    <h4 className="text-[16px] text-creovia-lightblue">Legal</h4>
                    <h2 className="text-[34px] font-light text-neutral-600 dark:text-light-primary">Privacy Policy</h2>
                    <p className="text-neutral-600 dark:text-light-primary">
                        Effective Date: January 1, 2025
                    </p>
                    <p className="text-neutral-600 dark:text-light-primary mt-4">
                        Creovia, LLC ("Creovia," "we," "us," or "our") is committed to protecting the privacy and security
                        of the personal information we collect. This Privacy Policy outlines how we collect, use, store,
                        and protect personal information, as well as your rights regarding your data.
                    </p>
                </motion.div>

                <PrivacySection title="1. Scope of This Privacy Policy">
                    <p className="text-lg">
                        This Privacy Policy applies to information collected through Creovia's website, services, and any
                        other interactions with us. By using our services, you agree to the practices described in this
                        Privacy Policy.
                    </p>
                </PrivacySection>

                <PrivacySection title="2. Information We Collect">
                    <div className="space-y-6">
                        <p className="text-lg">We collect the following types of information:</p>

                        <div>
                            <h3 className="font-semibold mb-2">Personal Information:</h3>
                            <ul className="list-disc pl-6 space-y-2">
                                <li>Name</li>
                                <li>Email address</li>
                                <li>Phone number</li>
                                <li>Address</li>
                                <li>Payment details</li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="font-semibold mb-2">Automatically Collected Information:</h3>
                            <ul className="list-disc pl-6 space-y-2">
                                <li>IP address</li>
                                <li>Browser type</li>
                                <li>Device information</li>
                                <li>Usage data (e.g., pages visited, time spent on the site)</li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="font-semibold mb-2">Other Information:</h3>
                            <ul className="list-disc pl-6 space-y-2">
                                <li>Any information you voluntarily provide through forms, surveys, or correspondence with us.</li>
                            </ul>
                        </div>
                    </div>
                </PrivacySection>

                <PrivacySection title="3. How We Use Your Information">
                    <p className="text-lg">We use the information we collect for the following purposes:</p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li>To provide and improve our services.</li>
                        <li>To communicate with you about your account, transactions, and updates.</li>
                        <li>To comply with legal obligations.</li>
                        <li>To analyze usage and trends to improve user experience.</li>
                        <li>To protect the security and integrity of our systems.</li>
                    </ul>
                </PrivacySection>

                <PrivacySection title="4. Disclosure of Your Information">
                    <p className="text-lg">We do not sell your personal information. We may share your information with:</p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li><strong>Service Providers:</strong> Third-party vendors who assist us in delivering our services (e.g., payment processors, IT support).</li>
                        <li><strong>Legal Authorities:</strong> When required by law, regulation, or legal process.</li>
                        <li><strong>Business Transfers:</strong> In the event of a merger, sale, or acquisition involving Creovia.</li>
                    </ul>
                </PrivacySection>

                <PrivacySection title="5. Your Rights and Choices">
                    <p className="text-lg">Depending on your jurisdiction, you may have the following rights:</p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li><strong>Access and Correction:</strong> Request access to or correction of your personal data.</li>
                        <li><strong>Deletion:</strong> Request the deletion of your personal information.</li>
                        <li><strong>Data Portability:</strong> Receive your data in a structured, commonly used format.</li>
                        <li><strong>Opt-Out:</strong> Manage your preferences for marketing communications.</li>
                    </ul>
                    <p className="text-lg mt-4">To exercise these rights, please contact us at support@creovia.io.</p>
                </PrivacySection>

                <PrivacySection title="6. Data Security">
                    <p className="text-lg">We implement robust security measures, including:</p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li>Data encryption during transmission and storage.</li>
                        <li>Regular security audits.</li>
                        <li>Employee training on data privacy.</li>
                    </ul>
                    <p className="text-lg mt-4">Despite our efforts, no method of transmission or storage is 100% secure. If you suspect a breach, please notify us immediately at support@creovia.io.</p>
                </PrivacySection>

                <PrivacySection title="7. Data Retention">
                    <p className="text-lg">
                        We retain personal information for as long as necessary to fulfill the purposes outlined in this policy, comply with legal obligations, or resolve disputes. Once the retention period expires, we securely delete or anonymize the data.
                    </p>
                </PrivacySection>

                <PrivacySection title="8. Third-Party Links">
                    <p className="text-lg">
                        Our website may contain links to third-party websites. We are not responsible for the privacy practices of these external sites. Please review their privacy policies before providing any information.
                    </p>
                </PrivacySection>

                <PrivacySection title="9. Children's Privacy">
                    <p className="text-lg">
                        Creovia does not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.
                    </p>
                </PrivacySection>

                <PrivacySection title="10. Education Records and FERPA Compliance">
                    <p className="text-lg">
                        As part of our services to educational institutions, Creovia may receive personally identifiable information (PII) contained in student records. These disclosures are authorized under the Family Educational Rights and Privacy Act (FERPA), a federal statute regulating the privacy of student records. Creovia:
                    </p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li>Accesses and processes PII solely for the purposes of fulfilling its contractual obligations with educational institutions.</li>
                        <li>Ensures that only authorized personnel with legitimate educational interests have access to PII.</li>
                        <li>Does not sell or use PII for marketing or advertising purposes.</li>
                        <li>Complies with FERPA's conditions on redisclosure, only sharing PII as authorized by law or the educational institution.</li>
                    </ul>
                    <p className="text-lg mt-4">
                        If a parent, legal guardian, or eligible student wishes to access or request amendments to their education records, they should contact the educational institution directly. Creovia will assist the institution in fulfilling such requests when directed.
                    </p>
                    <p className="text-lg mt-4">
                        In the event of a data breach involving education records, Creovia will notify the affected educational institution promptly and work to mitigate the issue, in compliance with FERPA and applicable state laws.
                    </p>
                </PrivacySection>

                <PrivacySection title="11. Changes to This Privacy Policy">
                    <p className="text-lg">
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Effective Date." We encourage you to review this policy periodically.
                    </p>
                </PrivacySection>

                <PrivacySection title="12. Contact Us">
                    <div className="space-y-2">
                        <p className="text-lg">
                            If you have any questions about this Privacy Policy or our data practices, please contact us:
                        </p>
                        <div className="mt-4">
                            <p>Creovia, LLC</p>
                            <p>6913 E. Rembrandt Ave</p>
                            <p>Mesa, AZ 85212</p>
                            <p className="mt-2">
                                <a href="tel:480.264.0025" className="text-blue-600 dark:text-[#60A5FA] hover:underline">
                                    480.264.0025
                                </a>
                            </p>
                            <p>
                                <a href="mailto:support@creovia.io" className="text-blue-600 dark:text-[#60A5FA] hover:underline">
                                    support@creovia.io
                                </a>
                            </p>
                        </div>
                    </div>
                </PrivacySection>

                <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, type: "tween" }}
                    viewport={{ once: true }}
                >
                    <p className="mt-8 text-neutral-600 dark:text-light-primary pb-12">
                        This Privacy Policy is designed to comply with applicable privacy laws, including but not limited to the General Data Protection Regulation (GDPR), the California Consumer Privacy Act (CCPA), and the Family Educational Rights and Privacy Act (FERPA).
                    </p>
                </motion.div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;