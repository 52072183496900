import React, {useEffect, useState} from "react";
import {createBrowserRouter, RouterProvider, Navigate} from "react-router-dom";
import HomePage from "./Routes/Home/HomePage";
import data from "./data.json";
import Company from "./Routes/Company/Company";
import NavBar from "./Routes/Home/NavBar/NavBar";
import BellsNavBar from "./Routes/Bells/BellsNavBar";
import Footer from "./components/Footer/Footer";
import {motion} from "framer-motion";
import {XMarkIcon} from "@heroicons/react/20/solid";
import Contact from "./Routes/Home/Contact/Contact";
import {Bells} from "./Routes/Bells/Bells";
import PrivacyPolicy from "./Routes/Privacy/PrivacyPolicy";
import DownloadPage from "./Routes/Downloads/downloads"

const App = () => {
    const [settings, setSettings] = useState({
        theme: localStorage.getItem('theme')
    });

    const [isMobileNavBarOpened, setIsMobileNavBarOpen] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const handleThemeChange = () => {
        let theme = settings.theme === 'dark' ? 'light' : 'dark';
        localStorage.setItem('theme', theme);
        setSettings({...settings, theme: theme})
    }

    useEffect(() => {
        if (localStorage.theme) {
            if (localStorage.theme === 'dark') {
                document.documentElement.classList.add('dark')
            } else if (localStorage.theme === 'light') {
                document.documentElement.classList.remove('dark')
            }
        } else {
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                document.documentElement.classList.add('dark');
                localStorage.setItem('theme', 'dark');
            } else {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('theme', 'light');
            }
        }
    }, [settings])

    const router = createBrowserRouter([
        {
            path: "/",
            element:
                <>
                    <NavBar settings={settings}
                            handleThemeChange={handleThemeChange}
                            data={data}
                            isMobileNavBarOpened={isMobileNavBarOpened}
                            setIsMobileNavBarOpen={setIsMobileNavBarOpen}
                            setShowContactModal={setShowContactModal}
                    />
                    {showContactModal &&
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{delay: 0.2, type: 'tween', duration: 0.5}}
                            className={"fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center pointer-events-auto"}
                            onClick={() => {
                                setShowContactModal(false)
                            }}
                        >
                            <div
                                className={"block sm:bg-opacity-0 p-5 bg-light-secondary dark:bg-dark-secondary rounded-xl mx-5 relative"}>
                                <button
                                    onClick={() => {
                                        setShowContactModal(false)
                                    }}
                                    className={"absolute sm:hidden dark:bg-dark-primary text-dark-primary dark:text-light-primary rounded-lg top-5 right-5 h-10 aspect-square"}>
                                    <XMarkIcon/>
                                </button>
                                <Contact onSubmitCB={(e) => {
                                    setShowContactModal(false);
                                }}/>
                            </div>
                        </motion.div>
                    }
                    <HomePage data={data} isMobileNavBarOpened={isMobileNavBarOpened} settings={settings}/>
                    <Footer data={data.footer_data} settings={settings}/>
                </>
        },
        {
            path: "/company",
            element:
                <>
                    <NavBar settings={settings}
                            isMobileNavBarOpened={isMobileNavBarOpened}
                            setIsMobileNavBarOpen={setIsMobileNavBarOpen}
                            handleThemeChange={handleThemeChange}
                            setShowContactModal={setShowContactModal}
                            data={data}/>
                    {showContactModal &&
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{delay: 0.2, type: 'tween', duration: 0.5}}
                            className={"fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center pointer-events-auto"}
                            onClick={() => {
                                setShowContactModal(false)
                            }}
                        >
                            <div
                                className={"block sm:bg-opacity-0 p-5 bg-light-secondary dark:bg-dark-secondary rounded-xl mx-5 relative"}>
                                <button
                                    onClick={() => {
                                        setShowContactModal(false)
                                    }}
                                    className={"absolute sm:hidden dark:bg-dark-primary text-dark-primary dark:text-light-primary rounded-lg top-5 right-5 h-10 aspect-square"}>
                                    <XMarkIcon/>
                                </button>
                                <Contact onSubmitCB={(e) => {
                                    setShowContactModal(false);
                                }}/>
                            </div>
                        </motion.div>
                    }
                    <Company data={data.about} settings={settings}/>
                    <Footer data={data.footer_data} settings={settings}/>
                </>
        },
        {
            path: "/bells",
            element:
                <>
                    <BellsNavBar settings={settings}
                            isMobileNavBarOpened={isMobileNavBarOpened}
                            setIsMobileNavBarOpen={setIsMobileNavBarOpen}
                            handleThemeChange={handleThemeChange}
                            setShowContactModal={setShowContactModal}
                            data={data}/>
                    {showContactModal &&
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{delay: 0.2, type: 'tween', duration: 0.5}}
                            className={"fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center pointer-events-auto"}
                            onClick={() => {
                                setShowContactModal(false)
                            }}
                        >
                            <div
                                className={"block sm:bg-opacity-0 p-5 bg-light-secondary dark:bg-dark-secondary rounded-xl mx-5 relative"}>
                                <button
                                    onClick={() => {
                                        setShowContactModal(false)
                                    }}
                                    className={"absolute sm:hidden dark:bg-dark-primary text-dark-primary dark:text-light-primary rounded-lg top-5 right-5 h-10 aspect-square"}>
                                    <XMarkIcon/>
                                </button>
                                <Contact onSubmitCB={(e) => {
                                    setShowContactModal(false);
                                }}/>
                            </div>
                        </motion.div>
                    }
                    <Bells data={data.bells} settings={settings}/>
                    <Footer data={data.footer_data} settings={settings}/>
                </>
        },
        {
            path: "/privacy-policy",
            element: <PrivacyPolicy />
        },
        {
    path: "/download",
    element:
                        <>
                    <NavBar settings={settings}
                            isMobileNavBarOpened={isMobileNavBarOpened}
                            setIsMobileNavBarOpen={setIsMobileNavBarOpen}
                            handleThemeChange={handleThemeChange}
                            setShowContactModal={setShowContactModal}
                            data={data}/>
                    {showContactModal &&
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{delay: 0.2, type: 'tween', duration: 0.5}}
                            className={"fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center pointer-events-auto"}
                            onClick={() => {
                                setShowContactModal(false)
                            }}
                        >
                            <div
                                className={"block sm:bg-opacity-0 p-5 bg-light-secondary dark:bg-dark-secondary rounded-xl mx-5 relative"}>
                                <button
                                    onClick={() => {
                                        setShowContactModal(false)
                                    }}
                                    className={"absolute sm:hidden dark:bg-dark-primary text-dark-primary dark:text-light-primary rounded-lg top-5 right-5 h-10 aspect-square"}>
                                    <XMarkIcon/>
                                </button>
                                <Contact onSubmitCB={(e) => {
                                    setShowContactModal(false);
                                }}/>
                            </div>
                        </motion.div>
                    }
                            <DownloadPage />
                    <Footer data={data.footer_data} settings={settings}/>
                </>

},
  {
    path: "/downloads",
    element: <Navigate to="/download" replace />
  }
    ])

    return (
        <>
            <RouterProvider router={router}/>
        </>
    )
}

export default App